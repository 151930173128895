import { AnimationOnScroll } from "react-animation-on-scroll"
import logo from "../Images/LOGO_v4_removebg.png"
import mic from "../Images/mic.png"
import { PiGuitarLight, PiMicrophoneStageLight, PiCameraLight, PiMusicNotesLight } from "react-icons/pi";
import { RiMailFill } from "react-icons/ri"
import Screen from "../Components/Screen"
import TextImage from "../Components/TextImage"
import symbols from "../Images/services.jpg"
import VSCarousel from "../Components/VSCarousel"
import { Link, useLocation } from "react-router-dom"
import Packages from "../Components/Packages"
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import bg from "../Images/carousel.jpg"
import suit from "../Images/suit.png"
import industry from "../Images/industry.png"
import city from "../Images/city.png"
import video from "../Images/video.png"
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
const Home = () => {
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const location = useLocation();
    const tablet = window.matchMedia("(max-width: 1024px)").matches
    const mobile = window.matchMedia("(max-width: 500px)").matches
    const renderHome = () => {
        return (
            <div className="home">
                <div className="home-nav">
                    <img src={logo} className="home-nav__logo" />
                    {!tablet && <div className="home-nav-links">
                        <Link className={`screen-nav-links__link ${location.pathname === "/" && "screen-nav-links__link--active"}`} to={"/"}>Home</Link>
                        <Link className={`screen-nav-links__link ${location.pathname === "/about-us" && "screen-nav-links__link--active"}`} to={"/about-us"}>About Us</Link>
                        <Link className={`screen-nav-links__link ${location.pathname === "/services" && "screen-nav-links__link--active"}`} to={"/services"}>Services</Link>
                        {/* <Link className={`screen-nav-links__link ${location.pathname === "/sample-tracks" && "screen-nav-links__link--active"}`} to={"/sample-tracks"}>Sample Tracks</Link> */}
                        {/* <Link className={`screen-nav-links__link ${location.pathname === "/gallery" && "screen-nav-links__link--active"}`} to={"/gallery"}>Gallery</Link> */}
                        <Link className={`screen-nav-links__link mr-3 ${location.pathname === "/contact-us" && "screen-nav-links__link--active"}`} to={"/contact-us"}>Contact Us</Link>
                    </div>}
                </div>
                <div className="home-con">
                    <p className="home-text home-text--white">CREATIVITY.</p>
                    <p className="home-text home-text--white">PRODUCTION.</p>
                    <p className="home-text home-text--gold">MUSIC.</p>
                </div>
                {mobile &&
                    <div className="d-flex justify-content-center align-items-center">
                        <p><FaAngleDown color="white" /> Scroll for more <FaAngleDown color="white" /></p>
                    </div>
                }
            </div>
        )
    }
    const sendMail = () => {
        emailjs
            .send('service_ruun834', 'template_fu0ysr6', { name: name, email: email, message: message }, {
                publicKey: '7xTS7kLMplcKrYIri',
            })
            .then(
                () => {
                    setName("")
                    setEmail("")
                    setMessage("")
                    toast.success('Contact form submitted!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <Screen homeItem={renderHome()}>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <TextImage
                image={symbols}
                title={
                    <h2 className="title">
                        <span className="title--thin">VAULT</span>
                        STUDIOS
                        <span className="title--white"> SHEFFIELD</span>
                    </h2>
                }
            >
                <p>Vault Studios is a premier music studio service located in the vibrant city of Sheffield, UK. Renowned for its commitment to excellence, Vault Studios offers a professional recording and production experience to musicians and artists of all genres. </p>
                <p>Equipped with state-of-the-art technology and a team of experienced sound engineers and producers, the studio ensures that every project receives the utmost attention and care.</p>
                <a href="mailto:admin@vaultstudios.co.uk">Admin@VaultStudios.co.uk</a>
            </TextImage>

            <VSCarousel
                items={[
                    {
                        title: "AVAILABILITY TO SUIT YOU",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">At Vault Studios, we are open all day, every day; including evenings, nights and weekends – to suit our clients at no extra cost</p>
                            </>,
                        img: suit
                    },
                    {
                        title: "HIGH QUALITY EQUIPMENT",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">We have a great range of instruments and both analog and digital equipment to get the perfect sound for each project</p>
                            </>,
                        img: bg
                    },
                    {
                        title: "INDUSTRY EXPERIENCE",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Everyone at Vault Studios has years of experience in their respective fields – bringing the best of the best to our clients</p>
                            </>,
                        img: industry
                    },
                    {
                        title: "GREAT LOCATION & FACILITIES",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Our location is right in the heart of Sheffield, and is purpose-built and secure to ensure everyone’s equipment is safe</p>
                            </>,
                        img: city
                    },
                    {
                        title: "ADD-ON SERVICES",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Not only do we produce music at Vault Studios – we can collate content for social media, promote your projects, and distribute across all platforms</p>
                            </>,
                        img: video
                    },
                ]}
            />
            <Packages
                skewed
                title="POPULAR PACKAGES"
                desc="At Vault Studios, we pride ourselves on offering a range of popular packages designed to cater to the diverse needs of musicians and artists. With our state-of-the-art facilities and experienced team, we aim to provide a seamless and rewarding music production experience."
                items={[
                    {
                        icon: <PiMicrophoneStageLight color="#F9C340" size={50} />,
                        price: "£150",
                        title: "STUDIO DAY RATE",
                        included: [
                            "Studio hire",
                            "First mix",
                            "Instrument usage",
                            "Recording equipment"
                        ]
                    },
                    {
                        icon: <PiCameraLight color="#F9C340" size={50} />,
                        price: "£200",
                        title: "LIVE SESSION",
                        included: [
                            "Professional video",
                            "Full audio mix",
                            "Three locations",
                            "Full video edit"
                        ]
                    },
                    {
                        icon: <PiGuitarLight color="#F9C340" size={50} />,
                        price: "£600",
                        title: "ALBUM MIX",
                        included: [
                            "Album recording",
                            "Final mix",
                            "Up to 10 tracks",
                            "Album distribution"
                        ]
                    },
                    {
                        icon: <PiMusicNotesLight color="#F9C340" size={50} />,
                        price: "£950",
                        title: "FULL ALBUM",
                        included: [
                            "Album recording",
                            "Final mixing",
                            "Album master",
                            "Up to 10 tracks",
                            "Album distribution"
                        ]
                    },
                ]}
            />
            <TextImage
                dark
                title={<h2 className="title">GET IN TOUCH WITH US</h2>}
                image={mic}
            >
                <p>Whether you're a seasoned musician or a newcomer to the music scene, we're here to support and guide you throughout your music production journey. Don't hesitate to reach out.</p>
                <Form.Group className="mb-3">
                    <Form.Label>Full name:</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter full name..." />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control value={email} onChange={(e) => setEmail(e.currentTarget.value)} type="email" placeholder="Enter email address..." />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Message:</Form.Label>
                    <Form.Control value={message} onChange={(e) => setMessage(e.currentTarget.value)} placeholder="Enter message..." as="textarea" rows={3} />
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => sendMail()} className="VSButton VSButton--gold">ENQUIRE</Button>
                </div>
            </TextImage>
        </Screen>
    )
}

export default Home